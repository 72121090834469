import { createSlice } from "@reduxjs/toolkit";
import { fetchToken, fetchLogin } from "../actions/loginAction";
// import { ILoginInitialState } from "../states/loginState";
import { REHYDRATE } from "redux-persist";

const initialState = {
  data: {
    id: 0,
    nome: "",
    senha: "",
    email: "",
    empresaID: 0,
    empresaNome: "",
    ativo: false,
  },
  isLoading: false,
  error: false,
};

export const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    token: (state, action) => {
      state.data = action.payload;
    },
    login: (state, action) => {
      state.data = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchToken.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchToken.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(fetchToken.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(fetchLogin.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchLogin.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(fetchLogin.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(REHYDRATE, (state) => {
      const { data } = state;
      state.isLoading = false;
      state.data = data;
    });
  },
});

export const { login } = loginSlice.actions;

export default loginSlice.reducer;
