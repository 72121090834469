import { createSlice } from "@reduxjs/toolkit";
import { fetchMiniTitulo } from "../actions/miniTituloAction";
// import { ILoginInitialState } from "../states/loginState";
import { REHYDRATE } from "redux-persist";

const initialState = {
  data: {
    id: 0,
    titulo_01: "",
    titulo_02: "",
    titulo_03: "",
    titulo_04: "",
    titulo_05: "",
    usuario_Criacao_ID: "",
    usuario_Criacao_Nome: "",
    data_Criacao: "",
    usuario_Alteracao_ID: "",
    usuario_Alteracao_Nome: "",
    data_Alteracao: "",
  },
  isLoading: false,
  error: false,
};

export const miniTituloSlice = createSlice({
  name: "miniTitulo",
  initialState,
  reducers: {
    miniTitulo: (state, action) => {
      state.data = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchMiniTitulo.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchMiniTitulo.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(fetchMiniTitulo.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(REHYDRATE, (state) => {
      const { data } = state;
      state.isLoading = false;
      state.data = data;
    });
  },
});

export const { login } = miniTituloSlice.actions;

export default miniTituloSlice.reducer;
