import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { getToken } from "../../utils/utils";

export const fetchMiniTitulo = createAsyncThunk(
  "miniTitulo/fetchMiniTitulo",
  async (data, thunkAPI) => {
    const url = process.env.REACT_APP_API_URL + "/api/miniTitulo";
    const options = {
      method: "GET",
      headers: {
        Authorization: "bearer " + getToken(),
      },
      data,
      url,
    };
    const response = await axios(options)
      .then((result) => {
        if (result.data) {
          // sessionStorage.setItem("token", result.data.access_token);
          return result.data;
        }
      })
      .catch((error) => {
        return error.code;
      });

    return response;
  }
);

export const fetchMiniTituloSearch = createAsyncThunk(
  "miniTitulo/fetchMiniTituloSearch",
  async (data, thunkAPI) => {
    const url =
      process.env.REACT_APP_API_URL + "/api/miniTitulo/search?search=" + data;
    const options = {
      method: "get",
      headers: { Authorization: "bearer " + getToken() },
      url,
    };
    const response = await axios(options)
      .then((result) => {
        if (result.data) {
          return result.data;
        } else {
          return "Error";
        }
      })
      .catch((error) => {
        return "Error:" + error?.response?.data?.ExceptionMessage;
      });

    return response;
  }
);

export const fetchMiniTituloEdit = createAsyncThunk(
  "miniTitulo/fetchMiniTituloEdit",
  async (data, thunkAPI) => {
    const url =
      process.env.REACT_APP_API_URL + "/api/miniTitulo/getByID?id=" + data.ID;
    const options = {
      method: "get",
      headers: { Authorization: "bearer " + getToken() },
      url,
    };
    const response = await axios(options)
      .then((result) => {
        if (result.data) {
          return result.data;
        } else {
          return "Error";
        }
      })
      .catch((error) => {
        return "Error:" + error?.response?.data?.ExceptionMessage;
      });

    return response;
  }
);

export const saveMiniTitulo = createAsyncThunk(
  "miniTitulo/saveMiniTitulo",
  async (data, thunkAPI) => {
    const url = process.env.REACT_APP_API_URL + "/api/miniTitulo";

    const options = {
      method: "post",
      headers: { Authorization: "bearer " + getToken() },
      url,
      data: data,
    };
    const response = await axios(options)
      .then((result) => {
        if (result.data) {
          return result.data;
        } else {
          return "Error";
        }
      })
      .catch((error) => {
        return "Error:" + error?.response?.data?.ExceptionMessage;
      });

    return response;
  }
);

export const editMiniTitulo = createAsyncThunk(
  "miniTitulo/editMiniTitulo",
  async (data, thunkAPI) => {
    const url = process.env.REACT_APP_API_URL + "/api/miniTitulo/" + data.ID;

    const options = {
      method: "put",
      headers: { Authorization: "bearer " + getToken() },
      url,
      data: data,
    };
    const response = await axios(options)
      .then((result) => {
        return result.status;
      })
      .catch((error) => {
        return "Error:" + error?.response?.data?.ExceptionMessage;
      });

    return response;
  }
);

export const deleteMiniTitulo = createAsyncThunk(
  "miniTitulo/deleteMiniTitulo",
  async (id, thunkAPI) => {
    const url = process.env.REACT_APP_API_URL + "/api/miniTitulo/" + id;

    const options = {
      method: "delete",
      headers: { Authorization: "bearer " + getToken() },
      url,
    };
    const response = await axios(options)
      .then((result) => {
        return result.status;
      })
      .catch((error) => {
        return "Error:" + error?.response?.data?.ExceptionMessage;
      });

    return response;
  }
);
