import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const fetchToken = createAsyncThunk(
  "fetchToken",
  async (data, thunkAPI) => {
    const url = process.env.REACT_APP_API_URL + "/api/Auth/authenticate";
    // const url = "https://dogsapi.origamid.dev/json/jwt-auth/v1/token";
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data,
      url,
    };
    const response = await axios(options)
      .then((result) => {
        if (result.data) {
          return result.data;
        }
      })
      .catch((error) => {
        return error.code;
      });

    return response;
  }
);

export const fetchLogin = createAsyncThunk(
  "fetchLogin",
  async (data, thunkAPI) => {
    const url = process.env.REACT_APP_API_URL + "/api/login";
    const dataUser = {
      Email: data.username,
      Senha: data.password,
    };
    const authorization = data.authorization;
    const options = {
      method: "post",
      headers: {
        Authorization: "bearer " + authorization,
      },
      data: dataUser,
      url,
    };
    const response = await axios(options)
      .then((result) => {
        if (result.data) {
          sessionStorage.setItem("ID", result.data.ID);
          sessionStorage.setItem("Nome", result.data.Nome);
          sessionStorage.setItem("EmpresaNome", result.data.EmpresaNome);
          sessionStorage.setItem("PermissaoNome", result.data.PermissaoNome);
          return result.data;
        }
      })
      .catch((error) => {
        return error.code;
      });

    return response;
  }
);
